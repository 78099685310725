<template>
    <div class="height">
        <vue-header title="中医问诊" :fixed="true" />
        <div class="question-box"
             v-bind:class="{nobox:questionBool}"
             v-if="doctor"
        >
            <div class="talk" v-for="(items,showIndex) in showData" v-bind:key="showIndex">
                <div class="flex" v-if="items.sayer=='me' && !(bool && showIndex==1) ">
                    <div class="question-head">
                        <img src="../../../images/me.png"/>
                    </div>
                    <div class="flex-1">
                        <div class="question-name">小助手</div>
                        <div class="question-tips" v-if="items.type=='talk'">{{items.data}}</div>
                        <div class="question-info quiz-info" v-if="items.type=='info'">
                            <div class="info-title">请填写您的基本信息</div>
                            <div class="info-quiz">
                                <div class="quiz" v-for="item in items.data" v-bind:key="item.question_id">
                                    <div class="flex" >
                                        <div class="white-space">{{item.question_id}}.{{item.name}}</div>
                                        <div class="flex-1" v-if="item.type=='radio'"></div>
                                        <div class="flex" v-if="item.type=='radio'">
                                            <div v-for="(sex,index) in item.data" v-bind:key="index" class="sex-item flex flex-y"
                                                 v-bind:class="{active:index+1==info.sex}"
                                                 @click="()=>change({key:'sex',value:index+1})"
                                            >
                                                <div class="info-sex"></div>
                                                <div>{{sex}}</div>
                                            </div>
                                        </div>
                                        <div v-if="item.type=='date'" class="flex flex-y flex-1" @click="timeChange">
                                            <div class="flex-1 info-time">{{info.date}}</div>
                                            <van-icon name="arrow" />
                                        </div>
                                        <div v-if="item.type=='height'" class="info-input flex-1">
                                            <input type='tel' :placeholder="`请输入您的身高`" v-model="height" />
                                        </div>
                                        <div v-if="item.type=='weight'" class="info-input flex-1">
                                            <input type='tel' :placeholder="`请输入您的体重`" v-model="weight" />
                                        </div>
                                    </div>
                                </div>
                                <div class="quiz-bottom flex" v-if='noClick==showIndex'>
                                    <div class="flex-1"></div>
                                    <button @click="()=>matterData({id:251,bool:bool})">确定</button>
                                </div>
                            </div>
                        </div>
                        <div class="question-info" v-if="items.type=='radio'">
                            <dl class="info-dl">
                                <dt>{{items.nameShow?items.data.question_name:'以下哪个符合您的症状'}}</dt>
                                <dd v-for="(matter,index) in items.data.son" v-bind:key="index" 
                                    @click="()=>answerChange({id:matter.id,index:items.showIndex,matter:matter,sIndex:showIndex})"
                                    v-bind:class="{active:matter.id==(JSON.parse(answer)[items.showIndex]?JSON.parse(answer)[items.showIndex].id:0)}"
                                >
                                    {{optFun(index)}}{{matter.question}}
                                </dd>
                            </dl>
                            <div class="quiz-padd">
                                <div class="quiz-bottom flex" v-if='noClick==showIndex'>
                                    <div class="flex-1"></div>
                                    <button @click="()=>matterData({id:JSON.parse(answer)[items.showIndex].id,bool:bool})">确定</button>
                                </div>
                            </div>
                        </div>
                        <div class="question-info" v-if="items.type=='checkbox'">
                            <dl class="info-dl info-checkbox">
                                <dt>{{items.nameShow?items.data.question_name:'以下哪个符合您的症状（多选）'}}</dt>
                                <dd v-for="(matter,index) in items.data.son" v-bind:key="index"
                                    v-bind:class="{active:multipleFun(matter.id,JSON.parse(answer)[items.showIndex])}"
                                >
                                    <div class="flex" @click="()=>answerMultiple({
                                            index:items.showIndex,
                                            sIndex:showIndex,
                                            ...matter
                                        })"
                                    >
                                        <div class="checkbox flex flex-y flex-x">
                                            <van-icon name="success" />
                                        </div>
                                        <div class="flex-1">{{matter.question}}</div>
                                    </div>
                                </dd>
                            </dl>
                            <div class="quiz-padd">
                                <div class="quiz-bottom flex" v-if='noClick==showIndex'>
                                    <div class="flex-1"></div>
                                    <button @click="()=>multipleAgent({
                                        data:JSON.parse(answer)[items.showIndex],
                                        index:items.showIndex
                                    })">确定</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex" v-if="items.sayer=='user'">
                    <div class="flex-1"></div>
                    <div class="flex">
                        <div class="flex-1">
                            <div class="question-name question-name-right">我</div>
                            <div class="question-answer">
                                <div v-for="(item,index) in items.data" v-bind:key="index">{{item}}</div>
                            </div>
                        </div>
                        <div class="question-head question-head-right">
                            <img src="../../../images/user.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="talk" v-if="questionBool">
                <div class="flex">
                    <div class="question-head">
                        <img src="../../../images/me.png"/>
                    </div>
                    <div class="flex-1">
                        <div class="question-name">小助手</div>
                        <div class="question-info quiz-info">
                            <dl class="info-dl">
                                <dt>不适症状补充（选填）</dt>
                                <dd class="info-dd">
                                    <textarea placeholder="请输入补充您的不适症状" v-model="doc"></textarea>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex question-button" v-if="questionBool">
                <button class="again-submit" @click="go()">重新填写</button>
                <button class="question-submit flex-1"  @click="submitFun">确认提交</button>
            </div>
            <div @click="go()" class="write-button" v-else>重新填写</div>
        </div>
        <div v-else>
             <div v-if="man" class="dooter">
                 您还不是医生
             </div>
        </div>
        <div v-if="timeBool" class="alert" @click.self="timeChange">
            <div class="alert-time">
                <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="请选择您的出生日期"
                    :formatter="formatter"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @cancel="timeChange"
                    @confirm="input"
                />
            </div>
        </div>
        <div class="loading flex flex-x flex-y" v-if="!man">
            <div class="loading-box">
                <div class="loading-icon">
                    <van-loading />
                    <div class="loading-ui">正在获取用户信息</div>
                </div>
            </div>
        </div>
        <div class="loading flex flex-x flex-y" v-if="loading">
            <div class="loading-box">
            <div class="loading-icon">
                <van-loading />
                <div class="loading-ui">提交中</div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueHeader from '@/components/header';
import { mapState , mapMutations , mapActions } from 'vuex';
import { Dialog } from 'vant';
export default {
  name: 'prescribeQuestion',
  data() {
    return {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(1990,0,1),
      bool:this.$route.params.id?true:false
    };
  },
  created:function(){
      if(this.$route.params.id){
        this.accountData();
        this.matterData({
          id:251,
          bool:this.bool
        });
      }else{
        this.usualFun();
      }
      this.lastData();
  },
  components:{
    vueHeader
  },
  computed:{
        height:{
            get() { return this.$store.state.prescribeQuestion.info.height },
            set(val){this.change({
                key:'height',
                value:val
            });}
        },
        weight:{
            get() { return this.$store.state.prescribeQuestion.info.weight },
            set(val){this.change({
                key:'weight',
                value:val
            });}
        },
        doc:{
            get() { return this.$store.state.prescribeQuestion.info.doc },
            set(val){this.change({
                key:'doc',
                value:val
            });}
        },
        ...mapState('prescribeQuestion',['showData','info','questionBool','answer','timeBool','noClick','loading','doctor','man']),
  },
  methods:{
      back(){
          window.history.back(-1);
      },
      go(){
            Dialog.confirm({
                title: '重新填写',
                message: '您确定需要重新填写问诊信息吗？',
            })
            .then(() => {
                window.history.go(0);
            })
      },
      input(value){
          this.timeSubmit(value)
      },
      formatter(type, val) {
        if (type === 'year') {
            return val + '年';
        }
        if (type === 'month') {
            return val + '月';
        }
        if (type === 'day') {
            return val + '日';
        }
        if (type === 'hour') {
            return val + '时';
        }
        if (type === 'minute') {
            return val + '分';
        }
        return val;
      },
      //多选判断
      multipleFun(id,answer){
          let bool = false;
          if(!answer){
              return false;
          }
          answer.map((item)=>{
              if(item.id==id){
                  bool=true
              }
          })
          return bool
      },
      submitFun(){
        this.submitData({
          _this:this,
          from_type:this.$route.query.from_type
        });
      },
      optFun(index){
          const abc = ['A','B','C','D','E','F','G','H','I','J','K','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
          return `${abc[index]}. `
      },
      ...mapMutations('prescribeQuestion',['change','answerChange','answerMultiple','multipleAgent','timeChange','timeSubmit','usualFun']),
      ...mapActions('prescribeQuestion',['matterData','submitData','accountData','lastData'])
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'prescribeQuestion';
</style>